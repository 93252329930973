<template>
  <!-- 자동완성 -->
  <ApprovalAutocomplete
    v-if="editable"
    v-model="selected"
    placeholder="조직 검색"
    :search-types="['ORGAN']"
    :error-message="organError"
    @input="v$.organ.$touch()"
    @blur="v$.organ.$touch()"
  />
  <!-- 조직 정보 -->
  <v-chip v-else small>
    {{ organ.name }}
  </v-chip>
</template>

<script>
import ApprovalAutocomplete from "@/approval/views/components/common/ApprovalAutocomplete";
import useVuelidate from "@vuelidate/core";
export default {
  name: "OrganSelectFieldItem",
  components: { ApprovalAutocomplete },
  setup: () => ({ v$: useVuelidate() }),
  props: {
    value: {
      type: Object,
      description: "필드 데이타 (v-model)"
    },
    templateFrame: {
      type: Object,
      required: true,
      description: "필드 속성"
    },
    editable: {
      type: Boolean,
      default: false,
      description: "편집 모드"
    }
  },
  computed: {
    organ: {
      get() {
        return {
          organId: this.value?.organId ?? "",
          name: this.value?.name ?? ""
        };
      },
      set(val) {
        this.$emit("input", { ...val });
      }
    },
    selected: {
      get() {
        const selected = {
          type: "ORGAN",
          name: this.organ.name,
          organId: this.organ.organId,
          valid: true
        };
        return this.organ?.organId > 0 ? selected : null;
      },
      set(val) {
        // 선택된 조직이 없을 경우
        if (!(val?.organId > 0)) {
          this.organ = { organId: "", name: "" };
          return;
        }
        // 조직이 선택되었을 경우
        const { organId, name } = val;
        this.organ = { organId, name };
      }
    },
    organDisplayName() {
      return `"${this.organ.name}"`;
    },
    organError() {
      const { $dirty, required } = this.v$.organ;
      const errors = [];
      if (!$dirty) return errors;
      if (required.$invalid) errors.push("필수 입력사항 입니다.");
      return errors;
    }
  },
  methods: {
    async validate() {
      return await this.v$.$validate();
    }
  },
  validations() {
    return {
      organ: {
        required: val => {
          if (!this?.templateFrame?.required) return true;
          return val?.organId || false;
        }
      }
    };
  },
  mounted() {
    this.$nextTick(() => {
      // 기본값 초기화 (수정일 경우 기존값 할당)
      this.organ = {
        organId: this.value?.organId ?? "",
        name: this.value?.name ?? ""
      };
    });
  }
};
</script>
